<template>
    <div class="register-box">
        <div class="step-panel step_1" v-if="step == 1">
            <p class="tip">请选择认证类型</p>
            <div class="btn" style="margin-top:10vh;">
                <van-button round block type="info" native-type="submit" @click="step = 2">个人认证</van-button>
            </div>
            <div class="btn" style="margin-top:30px;">
                <van-button round block type="info" native-type="submit" @click="step = 3;initData()">企业认证</van-button>
            </div>

            <div class="btn khsm">
                <b>开户需要满足：</b><br>
                1.个人身份证照片为实际拍照照片，不可扫描及截图；<br>
                2.营业执照为复印件，需要加盖公章； <br>
                3.年龄限制20-60 周岁;<br>
                4.私域进件：手持拍照，需手持身份证、营业执照清晰可见;<br>
                5.银行会随机进行电话核实，留意接听;
            </div>
        </div>
        <div class="step-panel step_2" v-if="step == 2">
            <div class="top-bar">
                个人认证
            </div>
            <div class="main-box">
                <p class="tip">请上传本人真实身份证信息</p>
                <div class="form-box">
                    <van-form @submit="handleSubmit">
                        <!-- <van-field  v-model="from.mobile" name="手机号码" label="手机号码" placeholder="请输入手机号码"  :rules="[{ required: true, message: '' },{ validator: asyncMobileValidator, message: '手机号不合法' }]"/> -->
                        <div class="uploader">
                            <van-uploader upload-icon="/images/front_bg.png" :max-count="1" accept="image/*"
                                :max-size="2 * 2048 * 1024" @oversize="onOversize"
                                :after-read="(val) => { uploadFile(val, 'ID_FRONT') }" v-model="idCardFrontUrlList" />
                            <p class="tip-txt">身份证正面照</p>
                        </div>
                        <div class="uploader">
                            <van-uploader upload-icon="/images/back_bg.png" :max-count="1" accept="image/*"
                                :max-size="2 * 2048 * 1024" @oversize="onOversize"
                                :after-read="(val) => { uploadFile(val, 'ID_BACK') }" v-model="idCardBackUrlList" />
                            <p class="tip-txt">身份证背面照</p>
                        </div>
                        <div class="btn" style="margin-top:30px;">
                            <van-button round block type="info" native-type="submit">立即认证</van-button>
                        </div>
                    </van-form>
                </div>
            </div>
        </div>
        <div class="step-panel step_3" v-if="step == 3">
            <div class="top-bar">
                企业认证
            </div>
            <div class="main-box">
                <p class="tip">请上传企业认证信息</p>
                <div class="form-box">
                    <van-form @submit="handleSubmit">

                        <van-field v-model="accountTypeDataCn" is-link readonly name="picker" label="开户类型"
                            placeholder="请选择开户类型" @click="showPickerAccountType = true" />
                        <van-popup v-model="showPickerAccountType" position="bottom">
                            <van-picker show-toolbar :columns="accountTypeData" @confirm="onConfirmAccountType"
                                @cancel="showPickerAccountType = false" />
                        </van-popup>

                        <van-field v-model="divideTypeDataCn" is-link readonly name="picker2" label="分账类型"
                            placeholder="请选择分账类型" @click="showPickerDivideType = true" />
                        <van-popup v-model="showPickerDivideType" position="bottom">
                            <van-picker show-toolbar :columns="divideTypeData" @confirm="onConfirmDivideType"
                                @cancel="showPickerDivideType = false" />
                        </van-popup>

                        <div class="uploader">
                            <van-uploader class="scan" upload-icon="/images/scan_bg.png" :max-count="1" accept="image/*"
                                :max-size="2 * 2048 * 1024" @oversize="onOversize"
                                :after-read="(val) => { uploadFile(val, 'BUSINESS_L') }" v-model="scanUrlList" />
                            <p class="tip-txt">营业执照</p>
                        </div>
                        <div class="uploader">
                            <van-uploader upload-icon="/images/front_bg.png" :max-count="1" accept="image/*"
                                :max-size="2 * 2048 * 1024" @oversize="onOversize"
                                :after-read="(val) => { uploadFile(val, 'ID_FRONT') }" v-model="idCardFrontUrlList" />
                            <p class="tip-txt">身份证正面照</p>
                        </div>
                        <div class="uploader">
                            <van-uploader upload-icon="/images/back_bg.png" :max-count="1" accept="image/*"
                                :max-size="2 * 2048 * 1024" @oversize="onOversize"
                                :after-read="(val) => { uploadFile(val, 'ID_BACK') }" v-model="idCardBackUrlList" />
                            <p class="tip-txt">身份证背面照</p>
                        </div>
                        <div v-if="!from.ext || from.ext.divideType!=2">
                            <div class="uploader">
                                <van-uploader upload-icon="/images/photo_bg.png" :max-count="1" accept="image/*"
                                    :max-size="2 * 2048 * 1024" @oversize="onOversize"
                                    :after-read="(val) => { uploadFile(val, null, 'doorplateImgFileUrl') }"
                                    v-model="doorplateImgFileUrl" />
                                <p class="tip-txt">门头照</p>
                            </div>
                            <div class="uploader">
                                <van-uploader upload-icon="/images/photo_bg.png" :max-count="1" accept="image/*"
                                    :max-size="2 * 2048 * 1024" @oversize="onOversize"
                                    :after-read="(val) => { uploadFile(val, null, 'legalHoldingIdImgFileUrl') }"
                                    v-model="legalHoldingIdImgFileUrl" />
                                <p class="tip-txt">法人手持身份证照片</p>
                            </div>
                            <div class="uploader">
                                <van-uploader upload-icon="/images/photo_bg.png" :max-count="1" accept="image/*"
                                    :max-size="2 * 2048 * 1024" @oversize="onOversize"
                                    :after-read="(val) => { uploadFile(val, null, 'legalHoldingScanImgFileUrl') }"
                                    v-model="legalHoldingScanImgFileUrl" />
                                <p class="tip-txt">法人手持营业执照照片</p>
                            </div>
                            <div class="uploader">
                                <van-uploader upload-icon="/images/photo_bg.png" :max-count="1" accept="image/*"
                                    :max-size="2 * 2048 * 1024" @oversize="onOversize"
                                    :after-read="(val) => { uploadFile(val, null, 'legalWithDoorplateImgFileUrl') }"
                                    v-model="legalWithDoorplateImgFileUrl" />
                                <p class="tip-txt">法人与门头合影照片</p>
                            </div>
                            <div class="uploader">
                                <van-uploader upload-icon="/images/video_bg.png" :max-count="1" accept="video/*"
                                    :max-size="2 * 2048 * 1024" @oversize="onOversize"
                                    :after-read="(val) => { uploadFile(val, null, 'officeImgFileUrl') }"
                                    v-model="officeImgFileUrl" />
                                <p class="tip-txt">办公场景视频</p>
                            </div>
                            <div class="uploader">
                                <van-uploader upload-icon="/images/photo_bg.png" :max-count="1" accept="image/*"
                                    :max-size="2 * 2048 * 1024" @oversize="onOversize"
                                    :after-read="(val) => { uploadFile(val, null, 'receptionImgFileUrl') }"
                                    v-model="receptionImgFileUrl" />
                                <p class="tip-txt">前台照图片照片</p>
                            </div>
                            <div class="uploader">
                                <van-uploader upload-icon="/images/photo_bg.png" :max-count="1" accept="image/*"
                                    :max-size="2 * 2048 * 1024" @oversize="onOversize"
                                    :after-read="(val) => { uploadFile(val, null, 'businessEntityUrl') }"
                                    v-model="businessEntityUrl" />
                                <p class="tip-txt">经营主体截图</p>
                            </div>

                            <div class="uploader">
                                <van-uploader upload-icon="/images/photo_bg.png" :max-count="1" accept="image/*"
                                    :max-size="2 * 2048 * 1024" @oversize="onOversize"
                                    :after-read="(val) => { uploadFile(val, null, 'mobileStoreHomeUrl') }"
                                    v-model="mobileStoreHomeUrl" />
                                <p class="tip-txt">手机端店铺主页</p>
                            </div>
                            <div class="uploader">
                                <van-uploader upload-icon="/images/photo_bg.png" :max-count="1" accept="image/*"
                                    :max-size="2 * 2048 * 1024" @oversize="onOversize"
                                    :after-read="(val) => { uploadFile(val, null, 'orderFlow1Url') }" v-model="orderFlow1Url" />
                                <p class="tip-txt">店铺半年订单流水1</p>
                            </div>
                            <div class="uploader">
                                <van-uploader upload-icon="/images/photo_bg.png" :max-count="1" accept="image/*"
                                    :max-size="2 * 2048 * 1024" @oversize="onOversize"
                                    :after-read="(val) => { uploadFile(val, null, 'orderFlow2Url') }" v-model="orderFlow2Url" />
                                <p class="tip-txt">店铺半年订单流水2</p>
                            </div>
                            <div class="uploader">
                                <van-uploader upload-icon="/images/photo_bg.png" :max-count="1" accept="image/*"
                                    :max-size="2 * 2048 * 1024" @oversize="onOversize"
                                    :after-read="(val) => { uploadFile(val, null, 'orderFlow3Url') }" v-model="orderFlow3Url" />
                                <p class="tip-txt">店铺半年订单流水3</p>
                            </div>
                            <div class="uploader">
                                <van-uploader upload-icon="/images/photo_bg.png" :max-count="1" accept="image/*"
                                    :max-size="2 * 2048 * 1024" @oversize="onOversize"
                                    :after-read="(val) => { uploadFile(val, null, 'orderFlow4Url') }" v-model="orderFlow4Url" />
                                <p class="tip-txt">店铺半年订单流水4</p>
                            </div>
                            <div class="uploader">
                                <van-uploader upload-icon="/images/photo_bg.png" :max-count="1" accept="image/*"
                                    :max-size="2 * 2048 * 1024" @oversize="onOversize"
                                    :after-read="(val) => { uploadFile(val, null, 'other1Url') }" v-model="other1Url" />
                                <p class="tip-txt">其他补充资料1</p>
                            </div>
                            <div class="uploader">
                                <van-uploader upload-icon="/images/photo_bg.png" :max-count="1" accept="image/*"
                                    :max-size="2 * 2048 * 1024" @oversize="onOversize"
                                    :after-read="(val) => { uploadFile(val, null, 'other2Url') }" v-model="other2Url" />
                                <p class="tip-txt">其他补充资料2</p>
                            </div>
                            <div class="uploader">
                                <van-uploader upload-icon="/images/photo_bg.png" :max-count="1" accept="image/*"
                                    :max-size="2 * 2048 * 1024" @oversize="onOversize"
                                    :after-read="(val) => { uploadFile(val, null, 'other3Url') }" v-model="other3Url" />
                                <p class="tip-txt">其他补充资料3</p>
                            </div>
                            <div class="uploader">
                                <van-uploader upload-icon="/images/photo_bg.png" :max-count="1" accept="image/*"
                                    :max-size="2 * 2048 * 1024" @oversize="onOversize"
                                    :after-read="(val) => { uploadFile(val, null, 'other4Url') }" v-model="other4Url" />
                                <p class="tip-txt">其他补充资料4</p>
                            </div>
                            <div class="uploader">
                                <van-uploader upload-icon="/images/photo_bg.png" :max-count="1" accept="image/*"
                                    :max-size="2 * 2048 * 1024" @oversize="onOversize"
                                    :after-read="(val) => { uploadFile(val, null, 'storeInfoUrl') }" v-model="storeInfoUrl" />
                                <p class="tip-txt">店铺信息</p>
                            </div>
                            <div class="uploader">
                                <van-uploader upload-icon="/images/photo_bg.png" :max-count="1" accept="image/*"
                                    :max-size="2 * 2048 * 1024" @oversize="onOversize"
                                    :after-read="(val) => { uploadFile(val, null, 'transactionData1Url') }"
                                    v-model="transactionData1Url" />
                                <p class="tip-txt">店铺交易数据1</p>
                            </div>
                            <div class="uploader">
                                <van-uploader upload-icon="/images/photo_bg.png" :max-count="1" accept="image/*"
                                    :max-size="2 * 2048 * 1024" @oversize="onOversize"
                                    :after-read="(val) => { uploadFile(val, null, 'transactionData2Url') }"
                                    v-model="transactionData2Url" />
                                <p class="tip-txt">店铺交易数据2</p>
                            </div>
                            <div class="uploader">
                                <van-uploader upload-icon="/images/photo_bg.png" :max-count="1" accept="image/*"
                                    :max-size="2 * 2048 * 1024" @oversize="onOversize"
                                    :after-read="(val) => { uploadFile(val, null, 'transactionData3Url') }"
                                    v-model="transactionData3Url" />
                                <p class="tip-txt">店铺交易数据3</p>
                            </div>
                            <div class="uploader">
                                <van-uploader upload-icon="/images/photo_bg.png" :max-count="1" accept="image/*"
                                    :max-size="2 * 2048 * 1024" @oversize="onOversize"
                                    :after-read="(val) => { uploadFile(val, null, 'transactionData4Url') }"
                                    v-model="transactionData4Url" />
                                <p class="tip-txt">店铺交易数据4</p>
                            </div>
                        </div>
                        <div class="btn" style="margin-top:30px;">
                            <van-button round block type="info" native-type="submit">立即认证</van-button>
                        </div>
                    </van-form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getRegisterEntAcct, uploadFile } from '@/api/api';
import { Dialog, Toast } from 'vant';
export default {
    name: "Register",
    data() {
        return {
            from: {
                realName: "",
                mobile: "",
                idCardFrontUrl: "",
                idCardBackUrl: "",
                idCard: "",
                certValidDateRange: "",
                ext: {
                    accountType: null, divideType: null
                }
            },
            showPickerAccountType: false,
            showPickerDivideType: false,
            idCardFrontUrlList: [],
            idCardBackUrlList: [],
            scanUrlList: [],
            step: 1,
            doorplateImgFileUrl: [],
            legalHoldingIdImgFileUrl: [],
            legalHoldingScanImgFileUrl: [],
            legalWithDoorplateImgFileUrl: [],
            officeImgFileUrl: [],
            receptionImgFileUrl: [],
            businessEntityUrl: [],
            mobileStoreHomeUrl: [],
            orderFlow1Url: [],
            orderFlow2Url: [],
            orderFlow3Url: [],
            orderFlow4Url: [],
            other1Url: [],
            other2Url: [],
            other3Url: [],
            other4Url: [],
            storeInfoUrl: [],
            transactionData1Url: [],
            transactionData2Url: [],
            transactionData3Url: [],
            transactionData4Url: [],
            accountTypeDataCn: '请选择开户类型',
            divideTypeDataCn: '请选择分账类型',
            accountTypeData: [

                { text: '企业', value: 2 },
                { text: '个体工商户', value: 3 },

            ],
            divideTypeData: [

                { text: '分账方', value: 1 },
                { text: '分账接收方', value: 2 },

            ],
        }
    },
    created() {
        let id = this.$route.query.id;
        let mchNo = this.$route.query.mchNo;
        if (id) {
            this.from.mchNo = mchNo;
            getRegisterEntAcct({ idCard: id, mchNo: mchNo }).then(res => {
                if (res.data) {
                    this.$router.push({ path: "/openaccount/binding?id=" + res.data.id + "&idCard=" + res.data.idCard + "&regNo=" + res.data.regNo + "&realName=" + res.data.realName + "&entName=" + res.data.entName + "&type=" + res.data.type }).catch(() => { });
                }
            })
        }
    },
    methods: {
        initData(){
            this.divideTypeDataCn = "分账接收方"
            if(!this.from.ext){
               this.from.ext={} 
            }
            this.from.ext.divideType = 2
        },
        onConfirmAccountType(selectedOptions) {
            this.accountTypeDataCn = selectedOptions.text
            this.from.ext.accountType = selectedOptions.value
            this.showPickerAccountType = false;
        },
        onConfirmDivideType(selectedOptions) {
            this.divideTypeDataCn = selectedOptions.text
            this.from.ext.divideType = selectedOptions.value
            this.showPickerDivideType = false;
        },

        toLink() {
            this.$router.push({ path: "/openaccount/binding" }).catch(() => { });
        },
        asyncMobileValidator(val) {
            return /^(?:(?:\+|00)86)?1(?:3[\d]|4[5-79]|5[0-35-9]|6[5-7]|7[0-8]|8[\d]|9[1589])\d{8}$/.test(val);
        },
        asyncFileValidator(val) {
            return val && val.length > 0;
        },
        uploadFile(file, type, field) {
            let _this = this;
            const formData = new FormData();
            formData.append('file', file.file);
            if (type != null) {
                formData.append('bizType', type);
            }
            const toast = Toast.loading({ duration: 0, message: '加载中...', forbidClick: true, loadingType: 'spinner', });
            uploadFile(formData).then((res) => {
                toast.clear();
                if (type != null) {
                    let content = JSON.parse(res.info);
                    if (type == 'ID_FRONT') {
                        _this.from['idCard'] = content.iDNo;
                        _this.from['realName'] = content.iDName
                        _this.from['idCardFrontUrl'] = res.fileUrl;
                    }
                    if (type == 'ID_BACK') {
                        if (content.endDate) {
                            _this.from['certValidDateRange'] = content.endDate;
                        } else {
                            _this.from['certValidDateRange'] = "99991231";
                        }
                        _this.from['idCardBackUrl'] = res.fileUrl;
                    }
                    if (type == 'BUSINESS_L') {
                        _this.from['entName'] = content.companyName;
                        _this.from['regNo'] = content.businessLicenseNo;
                        _this.from['scanImgFileUrl'] = res.fileUrl;
                    }
                } else {
                    if (!_this.from['ext']) {
                        _this.from['ext'] = {}
                    }
                    _this.from['ext'][field] = res.fileUrl;
                }

            }).catch((err) => {
                toast.clear();
                if (type == 'ID_FRONT') {
                    this.idCardFrontUrlList = [];
                }
                if (type == 'ID_BACK') {
                    this.idCardBackUrlList = [];
                }
                if (type == 'BUSINESS_L') {
                    this.scanUrlList = [];
                }
                this[field] == [];
                Dialog.alert({
                    title: '提示',
                    message: err,
                    theme: 'round-button',
                }).then(() => { });
            })
        },
        onOversize() {
            Dialog.alert({
                title: '提示',
                message: '文件大小不能超过4MB',
                theme: 'round-button',
            }).then(() => { });
        },
        handleSubmit() {
            if (this.step == 2) {
                //个人认证
                
                if (this.from.idCardFrontUrl && this.from.idCardBackUrl) {
                    this.from.ext=undefined;
                    this.from.mchNo = this.$route.query.mchNo;
                    this.$router.push({ path: "/openaccount/mobile?from=" + JSON.stringify(this.from) + "&type=idCard" }).catch(() => { });
                } else {
                    Dialog.alert({
                        title: '提示',
                        message: '请上传身份证正反面照片',
                        theme: 'round-button',
                    }).then(() => { });
                }
            }
            if (this.step == 3) {
                //企业认证
                if (!this.from.ext.accountType) {
                    Dialog.alert({
                        title: '提示',
                        message: '请选择开户类型',
                        theme: 'round-button',
                    }).then(() => { });
                    return;
                }
                if (!this.from.ext.divideType) {
                    Dialog.alert({
                        title: '提示',
                        message: '请选择分账类型',
                        theme: 'round-button',
                    }).then(() => { });
                    return;
                }
                if (!this.from.idCardFrontUrl) {
                    Dialog.alert({
                        title: '提示',
                        message: '请上传身份证正面照片',
                        theme: 'round-button',
                    }).then(() => { });
                    return;
                }
                if (!this.from.idCardBackUrl) {
                    Dialog.alert({
                        title: '提示',
                        message: '请上传身份证反面照片',
                        theme: 'round-button',
                    }).then(() => { });
                    return;
                }
                if (!this.from.scanImgFileUrl) {
                    Dialog.alert({
                        title: '提示',
                        message: '请上传营业执照照片',
                        theme: 'round-button',
                    }).then(() => { });
                    return;
                }
               
                // if (!this.from['ext']['doorplateImgFileUrl']) {
                //     Dialog.alert({
                //         title: '提示',
                //         message: '请上传门头照照片',
                //         theme: 'round-button',
                //     }).then(() => { });
                //     return;
                // }
                // if (!this.from['ext']['legalHoldingIdImgFileUrl']) {
                //     Dialog.alert({
                //         title: '提示',
                //         message: '请上传法人手持身份证照片',
                //         theme: 'round-button',
                //     }).then(() => { });
                //     return;
                // }
                // if (!this.from['ext']['legalHoldingScanImgFileUrl']) {
                //     Dialog.alert({
                //         title: '提示',
                //         message: '请上传法人手持营业执照照片',
                //         theme: 'round-button',
                //     }).then(() => { });
                //     return;
                // }
                // if (!this.from['ext']['legalWithDoorplateImgFileUrl']) {
                //     Dialog.alert({
                //         title: '提示',
                //         message: '请上传法人与门头合影照片',
                //         theme: 'round-button',
                //     }).then(() => { });
                //     return;
                // }
                // if (!this.from['ext']['officeImgFileUrl']) {
                //     Dialog.alert({
                //         title: '提示',
                //         message: '请上传办公场景视频',
                //         theme: 'round-button',
                //     }).then(() => { });
                //     return;
                // }
                // if (!this.from['ext']['receptionImgFileUrl']) {
                //     Dialog.alert({
                //         title: '提示',
                //         message: '请上传前台照片',
                //         theme: 'round-button',
                //     }).then(() => { });
                //     return;
                // }
                this.from.mchNo = this.$route.query.mchNo;
                this.$router.push({ path: "/openaccount/mobile?from=" + JSON.stringify(this.from) + "&type=scan" }).catch(() => { });
            }


        }
    }
}
</script>
<style scoped>
.main-box .tip {
    font-size: 30px;
    color: cornflowerblue;
}

.register-box {
    width: 100%;
    height: calc(100vh);
    margin: 0px;
    padding: 0px;
    overflow-y: scroll;

}

.register-box .top-bar {
    line-height: 2;
    font-size: 40px;
    padding: 20px;
    font-weight: 550;
    text-align: center;
}

.uploader {
    margin: 5px 16px;
    text-align: center;
}

.uploader .tip-txt {
    font-size: 33px;
    line-height: 1;
    font-weight: 550;
}

::v-deep .van-cell__title.van-field__label,
::v-deep .van-cell__value.van-field__value {
    font-size: 30px;
    line-height: 3;
}

::v-deep .van-field__error-message {
    font-size: 30px;
}

::v-deep .van-uploader__upload {
    width: calc(100vw - 16px);
    height: 50vw;
    border-radius: 10px;
}

::v-deep .van-uploader__wrapper,
::v-deep .van-uploader__wrapper .van-uploader__preview,
::v-deep .van-uploader__wrapper .van-uploader__preview .van-image,
::v-deep .van-uploader__wrapper,
::v-deep .van-uploader__wrapper .van-uploader__file {
    width: calc(100vw - 34px);
    height: 50vw;
    border-radius: 10px;
}

::v-deep .van-uploader__preview-delete {
    display: none;
}

::v-deep .van-uploader__preview-delete .van-icon {
    font-size: 55px;
}

::v-deep .van-uploader__upload-icon,
::v-deep .van-uploader__upload-icon img {
    width: calc(100vw - 34px);
    height: 50vw;
}

::v-deep .van-uploader__upload-icon img {
    height: calc(50vw - 40px);
    margin: 20px auto;
}

::v-deep .van-button {
    font-size: 30px;
    height: 80px;
}

.btn {
    margin: 20px;
}

.khsm {
    margin-top: 100px;
    text-align: left;
    font-size: 0.4rem;
    padding-left: 20px;
    padding-right: 10px;
    line-height: 180%;
    color: red;
}

.step_1 .tip {
    margin-top: 20px;
    font-size: 45px;
}

</style>
<style>
.van-cell__right-icon {
    margin-top:20px !important;
}
</style>